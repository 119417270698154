(function ($) {

    $('.projects .slides').slick({
        arrows:true,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1659,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 771,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 1,
                }
            }
        ],
        prevArrow: '.btn-left',
        nextArrow: '.btn-right'
    });



    $('.fixed-header nav ul .parent').on('click',function(e){
        e.preventDefault();
        if($(this).hasClass('active')){
            $(this).removeClass('active');
            $(this).find('.child').css('height','0px');
        }else{
            $(this).addClass('active');
            var height = +$(this).find('ul').outerHeight();
            $(this).find('.child').css('height', height+'px');
        }
    });
    $(document).mouseup(function (e){ // событие клика по веб-документу
        var $menu = $(".fixed-header nav ul .parent.active .child"); // тут указываем ID элемента
        if (!$menu.is(e.target) // если клик был не по нашему блоку
            && $menu.has(e.target).length === 0) {
            $menu.css('height','0px');
            $menu.parents('ul').find('.parent').removeClass('active');
        }
    });

    $.fancybox.defaults.modal = true;
    $('.modal-link-price').fancybox({
        onActivate:function(){
            $('.menu-container.active').removeClass('active');
            $('body').removeClass('menu-active');
        }
    });
    $('.modal-link-price').on('click', function(e){
        e.preventDefault();
        $('#price .head-modal .value').text($(this).text());
        $('#price .body-modal input[name="price-product"]').val($(this).text());
    });
    $('.modal-link-consultation-product').fancybox({
        onActivate:function(){
            $('.menu-container.active').removeClass('active');
            $('body').removeClass('menu-active');
        }
    });
    $('.modal-link-consultation-product').on('click', function(e){
        e.preventDefault();
        $('#consultation-product .head-modal .value').text($(this).text());
        $('#consultation-product .body-modal input[name="consultation-product"]').val($(this).text());
    });
    $('.modal-link-consultation').fancybox({
        onActivate:function(){
            $('.menu-container.active').removeClass('active');
            $('body').removeClass('menu-active');
        }
    });
    $('.modal-link-partner').fancybox({
        onActivate:function(){
            $('.menu-container.active').removeClass('active');
            $('body').removeClass('menu-active');
        }
    });
    $('.btn-close').on('click', function(){
        $.fancybox.close();
    });



    $('.container-modal label').on('click', function(e){
        $(this).toggleClass('active');
    });
    $('.menu-link').on('click',function(){
        $('.menu-container').addClass('active');
        $('body').addClass('menu-active');
    });

    $(document).mouseup(function (e){ // событие клика по веб-документу
        var menu = $(".menu-container.active nav"); // тут указываем ID элемента
        if (!menu.is(e.target) // если клик был не по нашему блоку
            && menu.has(e.target).length === 0) { // и не по его дочерним элементам\
            menu.parents('.menu-container.active').removeClass('active'); // скрываем его
            menu.parents('body').removeClass('menu-active'); // скрываем его
        }
    });
    $('.menu-container .parent').on('click', function(e){
        e.stopPropagation();
        $(this).toggleClass('active');
        $(this).find('.parent.active').each(function(){
            $(this).removeClass('active');
        });
    })
    $('.menu-container.active .btn-close').on('click',function(e){
        e.stopPropagation();
        $(this).parent().removeClass('active');

    });

    $(".fixed-header nav ul li.not-parent>a, footer .blocks-list li a, .menu-container .main-item.not-parent a").on("click", function (event) {
        event.preventDefault();
        var top = $(this.hash).offset().top-80;
        //анимируем переход на расстояние - top за 1500 мс
        $('body,html').animate({scrollTop: top}, 1000);
        $('.menu-container.active').removeClass('active');
        $('body').removeClass('menu-active');
    });


    $(window).scroll(function(){
        var heightHeader = +$('header').outerHeight();
        if($(this).scrollTop()>heightHeader){
            $('.fixed-header nav ul .main' ).each(function(index){
                $(this).css('transition-delay', ((2*(index+1))/20)+'s');
            })
            $('.fixed-header').addClass('active');
        }else{
            $('.fixed-header').removeClass('active');
            $('.fixed-header nav ul .main' ).each(function(index){
                $(this).css('transition-delay', '0s');
            });
            $('.parent.main.active>.child').css('height', '0px');
            $('.parent.main.active').removeClass('active');
        }
    });

    $(window).scroll(function(e){
        if(+$(window).scrollTop()<scrollValue){
            $('.fixed-header .main>a').addClass('reverse');
            scrollValue = +$(window).scrollTop();
        }else{
            $('.fixed-header .main>a').removeClass('reverse');
            scrollValue = +$(window).scrollTop();
        }
        $('.scroll-block').each(function(){
            if(+$(window).scrollTop()>+($(this).offset().top-160) && +$(window).scrollTop()<(+$(this).outerHeight()+$(this).offset().top)){
                $('.fixed-header a').removeClass('active');
                $('.fixed-header a[href="#'+$(this).attr('id')+'"]').addClass('active');
            }else{
                $('.fixed-header a[href="#'+$(this).attr('id')+'"]').removeClass('active');
            }
        });
    });

    var scrollValue = 0;

    $('.modal-link-project-consultation').fancybox();
    $('.modal-link-project-consultation').on('click', function(e){
        e.preventDefault();
        $('#consultation-project .head-modal .value').text($('h1').text());
        $('#consultation-project .body-modal input[name="consultation-project"]').val($('h1').text());
    });

    $('.modal-link-project-price').fancybox();
    $('.modal-link-project-price').on('click', function(e){
        e.preventDefault();
        $('#price-project .head-modal .value').text($('h1').text());
        $('#price-project .body-modal input[name="price-project"]').val($('h1').text());
    });
    $('.modal-link-project-order').fancybox();
    $('.modal-link-project-order').on('click', function(e){
        e.preventDefault();
        $('#order-project .head-modal .value').text($('h1').text());
        $('#order-project .body-modal input[name="order-project"]').val($('h1').text());
    });


    $('form').on('submit',function(e){
        e.preventDefault();
       /* var i=0;*/
       /* $(this).find('.required').each(function(){
            if(!$(this).find('input').val()){
                $(this).addClass('error');
                i++;
            }
        });
        if(i>0 || !$('.container-politics input').attr('checked')){
            return;
        }*/

        var form = new FormData(this);
        $.ajax({
            type: "POST",
            url: $(this).attr('action'),
            data: form,
            processData: false,
            contentType: false,
            success: function() {
                $.fancybox.close();
                console.log('успех');
            } ,
            error:function(){
                console.log('ошибка')
            }
        });


    });
}(jQuery));